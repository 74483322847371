import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Heading from "../../components/Heading/Heading";
import YouTubeEmbed from "../../components/YouTubeEmbed/YouTubeEmbed";
import Modal from "../../components/Modal/Modal";
import { YOUTUBE_PROMO_URL } from "../../helpers/constants";
import useTranslations from "../../hoc/useTranslations";

import PlaySVG from "../../assets/icons/play.svg";

import "./ExplanationVideo.scss";

const ExplanationVideo = () => {
	const { explanation_video } = useTranslations();
	const [showVideo, setShowVideo] = useState(false);

	const toggleVideo = () => setShowVideo(!showVideo);

	const data = useStaticQuery(graphql`
		query {
			explanation: file(relativePath: { eq: "explanation_video_thumbnail.png" }) {
				childImageSharp {
					fluid(maxWidth: 750, quality: 60) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`);

	const { fluid } = data.explanation.childImageSharp;

	return (
		<section className="section explanation__section">
			<Heading type={2} className="explanation__heading">
				{explanation_video}
			</Heading>
			<div className="explanation__container" onClick={toggleVideo}>
				<div className="explanation__container--thumbnail">
					<Img
						className="explanation__container--image"
						fluid={fluid}
						fadeIn={false}
						loading="eager"
						alt={explanation_video}
						title={explanation_video}
					/>
				</div>
				<button className="explanation__container--play" onClick={() => {}}>
					<PlaySVG />
				</button>
			</div>
			{showVideo && (
				<Modal closeModal={toggleVideo} className="explanation__modal">
					<div className="explanation__modal--video">
						<YouTubeEmbed embedId={YOUTUBE_PROMO_URL} autoplay />
					</div>
				</Modal>
			)}
		</section>
	);
};

export default ExplanationVideo;
