import React, { useContext } from "react";
import PropTypes from "prop-types";
import { LocaleContext } from "../../components/Layout/Layout";
import useTranslations from "../../hoc/useTranslations";
import Heading from "../../components/Heading/Heading";
import Department from "./Department";

import CCIconSVG from "../../assets/icons/customer_care_icon.svg";
import CCImageSVG from "../../assets/icons/customer_care_image.svg";
import HRIconSVG from "../../assets/icons/hr_icon.svg";
import HRImageSVG from "../../assets/icons/hr_image.svg";
import "./Departments.scss";

import animation_1 from "../../assets/animations/customer_care_1.json";
import animation_2 from "../../assets/animations/customer_care_2.json";
import animation_3 from "../../assets/animations/how_it_works_3.json";

const animations = [
	{
		loop: true,
		autoplay: false,
		animationData: animation_1,
	},
	{
		loop: true,
		autoplay: false,
		animationData: animation_2,
	},
	{
		loop: true,
		autoplay: true,
		animationData: animation_3,
	},
];

const Departments = ({ duplicate }) => {
	const { forPage } = useContext(LocaleContext);

	const {
		[forPage === "customer_care" && duplicate ? "how_it_works" : "departments"]: {
			title,
			sections,
		},
	} = useTranslations();

	const resources = [
		{
			icon: <CCIconSVG className="departments__section--icon" />,
			image: <CCImageSVG className="departments__section--image" />,
		},
		{
			icon: <HRIconSVG className="departments__section--icon" />,
			image: <HRImageSVG className="departments__section--image" />,
		},
	];

	return (
		<section className="section section__departments">
			<Heading className="departments__heading" type={2}>
				{title}
			</Heading>
			{sections.map(({ title, description, items }, index) => (
				<Department
					key={title}
					title={title}
					items={items}
					description={description}
					duplicate={duplicate}
					animationSettings={animations[index]}
					resources={resources[index + 1 > resources.length ? (index % 2 !== 0 ? 1 : 0) : index]}
				/>
			))}
		</section>
	);
};

Departments.propTypes = {
	duplicate: PropTypes.bool,
};

Departments.defaultProps = {
	duplicate: false,
};

export default Departments;
