import React from "react";
import PropTypes from "prop-types";

const DepartmentListItem = ({ children }) => (
	<li className="departments__section--list-item">{children}</li>
);

DepartmentListItem.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DepartmentListItem;
