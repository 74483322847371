import React, { useContext } from "react";
import PropTypes from "prop-types";
import useTranslations from "../../hoc/useTranslations";
import { LocaleContext } from "../../components/Layout/Layout";
import Button from "../../components/Button/Button";
import Heading from "../../components/Heading/Heading";

import "./CallToAction.scss";

const CallToAction = ({ toggleModal }) => {
	const { call_to_action, getDemoButton } = useTranslations();
	const { forPage } = useContext(LocaleContext);

	const handleClick = () => toggleModal("CTA Section");

	return (
		<section className="section section__callToAction">
			<Heading type={2} className="section__callToAction--heading">
				{forPage === "customer_care" ? call_to_action.title : call_to_action}
			</Heading>
			{forPage === "customer_care" ? (
				<a
					href={call_to_action.href}
					target="_blank"
					rel="noopener noreferrer"
					className="section__callToAction--customerCareButton"
				>
					{call_to_action.button}
				</a>
			) : (
				<Button
					buttonClass="alternative"
					classNames={["section__callToAction--button"]}
					onClick={handleClick}
				>
					{getDemoButton}
				</Button>
			)}
		</section>
	);
};

CallToAction.propTypes = {
	toggleModal: PropTypes.func.isRequired,
};

export default CallToAction;
