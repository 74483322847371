import React, { useState } from 'react';
import Lottie from 'react-lottie';
import VizSensor from 'react-visibility-sensor';


const Animation = ({ settings }) => {
    const [shouldPlay, setShouldPlay] = useState(false);

    const handleChangePlayState = (visible) => setShouldPlay(visible);

    return (
        <div className="howItWorks__animation">
            <VizSensor onChange={handleChangePlayState}>
                <Lottie isPaused={!shouldPlay} options={settings} />
            </VizSensor>
        </div>
    );
}

export default Animation;
