import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { PAGE } from "../../helpers/constants";
import { scrollToTop } from "../../helpers/helpers";
import CallToAction from "../CallToAction/CallToAction";
import Departments from "../Departments/Departments";
import Header from "../Header/Header";
import InfoCards from "../InfoCards/InfoCards";
import Integrations from "../Integrations/Integrations";
import Reviews from "../Reviews/Reviews";
import SEO from "../../components/seo";
import ExplanationVideo from "../ExplanationVideo/ExplanationVideo";

const CustomerCareFin = ({ toggleModal, locale }) => {
	useEffect(() => {
		scrollToTop();
	}, []);

	return (
		<>
			<SEO papage={PAGE.INDEX} />
			<Header toggleModal={toggleModal} />
			<main>
				{locale === "pl" && <ExplanationVideo />}
				<InfoCards />
				<Departments duplicate />
				<CallToAction toggleModal={toggleModal} />
				<Departments />
				<Integrations toggleModal={toggleModal} />
				<Reviews />
			</main>
		</>
	);
};

CustomerCareFin.propTypes = {
	toggleModal: PropTypes.func.isRequired,
	locale: PropTypes.string.isRequired,
};

export default CustomerCareFin;
