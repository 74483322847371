import React from 'react';
import PropTypes from 'prop-types';
import DepartmentListItem from './DepartmentListItem';

const DepartmentList = ({className, items}) => (
    <ul className={className}>
        {items.map(item => (
            <DepartmentListItem key={item}>{item}</DepartmentListItem>
        ))}
    </ul>
);

DepartmentList.propTypes = {
    className: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default DepartmentList;