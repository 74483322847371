import React from "react";
import PropTypes from "prop-types";
import useTranslations from "../../hoc/useTranslations";
import Heading from "../../components/Heading/Heading";
import Description from "../../components/Description/Description";
import Button from "../../components/Button/Button";

import CloudSVG from "../../assets/icons/cloud.svg";
import SharepointSVG from "../../assets/icons/sharepoint.svg";
import WavesSVG from "../../assets/icons/waves.svg";
import FolderSVG from "../../assets/icons/folder.svg";
import DiskSVG from "../../assets/icons/disk.svg";
import DriveSVG from "../../assets/icons/drive.svg";
import ZendeskSVG from "../../assets/icons/zendesk.svg";
import NextcloudSVG from "../../assets/icons/nextcloud.svg";

import "./Integrations.scss";

const Integrations = ({ toggleModal }) => {
	const {
		integrations: { title, description },
		getDemoButton,
	} = useTranslations();

	return (
		<section className="section section__integrations">
			<div className="section__integrations--overlay">
				<CloudSVG className="section__integrations--overlay-icon" />
				<SharepointSVG className="section__integrations--overlay-icon" />
				<WavesSVG className="section__integrations--overlay-icon" />
				<FolderSVG className="section__integrations--overlay-icon" />
				<DiskSVG className="section__integrations--overlay-icon" />
				<DriveSVG className="section__integrations--overlay-icon" />
				<ZendeskSVG className="section__integrations--overlay-icon" />
				<NextcloudSVG className="section__integrations--overlay-icon" />
			</div>
			<Heading className="section__integrations--title" type={2}>
				{title}
			</Heading>
			<Description className="section__integrations--description">{description}</Description>
			<Button
				buttonClass="alternative"
				onClick={() => toggleModal("Integrations CTA")}
				classNames={["section__integrations--button"]}
			>
				{getDemoButton}
			</Button>
		</section>
	);
};

Integrations.propTypes = {
	toggleModal: PropTypes.func.isRequired,
};

export default Integrations;
