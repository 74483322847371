import React, { useContext } from "react";
import PropTypes from "prop-types";
import { LocaleContext } from "../../components/Layout/Layout";
import Heading from "../../components/Heading/Heading";
import Description from "../../components/Description/Description";
import DepartmentList from "./DepartmentList";
import Animation from "../HowItWorks/Animation";

const Department = ({ title, items, resources, description, animationSettings, duplicate }) => {
	const { forPage } = useContext(LocaleContext);

	return (
		<section className="departments__section">
			{forPage === "customer_care" && duplicate ? (
				<Animation settings={animationSettings} className="howItWorks__container--animation" />
			) : (
				resources.image
			)}
			<div className="departments__section--container">
				<Heading type={3} className="departments__section--title">
					{resources.icon}
					{title}
				</Heading>
				{forPage === "customer_care" ? (
					<Description>{description}</Description>
				) : (
					<DepartmentList className="departments__section--list" items={items} />
				)}
			</div>
		</section>
	);
};

Department.propTypes = {
	title: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(PropTypes.string),
	resources: PropTypes.objectOf(PropTypes.node.isRequired).isRequired,
	animationSettings: PropTypes.objectOf(
		PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
	).isRequired,
	description: PropTypes.string,
	duplicate: PropTypes.bool,
};

Department.defaultProps = {
	items: [],
	description: "",
	duplicate: false,
};

export default Department;
